import React, { Component, useRef } from 'react';
import { Link } from 'gatsby';
import Slider from 'react-slick';

import SEO from '../components/SEO';
import HeadingSection from '../components/HeadingSection';
import SuccessModalBlock from '../components/SuccessModalBlock';

import imageIllustrationSwitchMortgage from '../assets/images/illustrations/About_sprive.png';
import iconTrustpilotStart from '../assets/images/icons/other/trustpilot-stars.png';
import iconQuote from '../assets/images/icons/other/left-quote.svg';
import IconLeftArrow from '../assets/images/icons/other/left-arrow.inline.svg';
import IconRightArrow from '../assets/images/icons/other/right-arrow.inline.svg';
import IconAlbanyPark from '../assets/images/icons/other/albany-park.png';


function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const SwitchMortgage = () => (
  <>
    <SEO title="Get in touch" description="" />

    <div className="common-page get-in-touch">
      <HeadingSection
        h1="Switch mortgage"
        h2="Looking to remortgage? Free, independent advice that saves you time, hassle, and money"
        image={imageIllustrationSwitchMortgage}
      />
      <FirstSection />
      <SecondSection />
      <ContactForm />
    </div>
  </>
);

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slider-arrow slider-arrow--next" onClick={onClick}>
      <IconRightArrow />
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="slider-arrow slider-arrow--prev" onClick={onClick}>
      <IconLeftArrow />
    </div>
  );
}

const FirstSection = () => {
  const settings = {
    autoplay: true,
    arrows: true,
    dots: false,
    infinite: true,
    speed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 767.98,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="second-section">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-5">
          <div className="trustpilot">
            <div className="trustpilot__top" />
            <div className="trustpilot__bottom">
              <img src={iconTrustpilotStart} alt="trustpilot" />
              <div>
                <span className="bold">4.9</span>
                <span className="regular"> of 25678 reviews</span>
              </div>
            </div>
          </div>
        </div> */}

          {/* <div className="col-sm-1">

            <img src={IconAlbanyPark} alt="albanypark" />
          </div> */}
          <div className="col-lg">
            <div className="slider">
              <Slider {...settings}>
                <div className="w-100">
                  <div className="slide">
                    <div className="slide__quote">
                      <img src={iconQuote} alt="quote" />
                    </div>
                    <div className="slide__content">
                      <p className="review">
                        The advisor was very patient and understanding. Very helpful also and explained everything in great detail.
                      </p>
                      <div className="slide__content__bottom">
                        <div className="author">Kerry</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <div className="slide">
                    <div className="slide__quote">
                      <img src={iconQuote} alt="quote" />
                    </div>
                    <div className="slide__content">
                      <p className="review">
                      Very helpful and informative. Talked me through everything thoroughly
                      </p>
                      <div className="slide__content__bottom">
                        <div className="author">Susan Shaw</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <div className="slide">
                    <div className="slide__quote">
                      <img src={iconQuote} alt="quote" />
                    </div>
                    <div className="slide__content">
                      <p className="review">
                      Lovely customer service and extremely helpful
                      </p>
                      <div className="slide__content__bottom">
                        <div className="author">Gemma Brown</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <div className="slide">
                    <div className="slide__quote">
                      <img src={iconQuote} alt="quote" />
                    </div>
                    <div className="slide__content">
                      <p className="review">
                      Thank you so much we were very impressed. A really great help to us.                      </p>
                      <div className="slide__content__bottom">
                        <div className="author">Katie Thorpe</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <div className="slide">
                    <div className="slide__quote">
                      <img src={iconQuote} alt="quote" />
                    </div>
                    <div className="slide__content">
                      <p className="review">
                      Excellent customer service. James took the time to go through all of my options with me and that I was getting the best deal. Would highly recommend.
                      </p>
                      <div className="slide__content__bottom">
                        <div className="author">Debbie Edgar</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="poweredby__content"><p className='label'>Powered by Connect IFA Ltd</p></div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SecondSection = () => {

  return (
    <section className="first-section">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-5">
          <div className="trustpilot">
            <div className="trustpilot__top" />
            <div className="trustpilot__bottom">
              <img src={iconTrustpilotStart} alt="trustpilot" />
              <div>
                <span className="bold">4.9</span>
                <span className="regular"> of 25678 reviews</span>
              </div>
            </div>
          </div>
        </div> */}

        </div>
      </div>
    </section>
  );
};

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.childSuccessModalBlockRef = React.createRef();
  }

  handleChange = (event) => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  handleSubmitForm = (event) => {
    event.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'switch-mortgage-form',
        'subject': 'Mortgage lead from sprive.com',
        ...this.state,
      }),
    })
      .then((response) => {
        if (response.ok) {
          this.childSuccessModalBlockRef.current.openModal();
          this.setState({ name: '', email: '', message: '', phone: '' });
        }
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { name, email, message, phone } = this.state;
    const textAreaMessage = `Please feel free to add any additional information that may help us ahead of our call, such as…
    - Reason for getting in touch e.g. looking to remortgage, exit my deal early
    - Preferred call back time
    - Details around your existing mortgage
    - Preference around the type of new deal you’re looking for`
    return (

      <>      
        <section className="first-section">
          <div className="container">
            <div className="first-section_content">
              <h2>Speak to a mortgage expert</h2>
              <form
                className="wrapper"
                method="POST"
                action="/"
                name="switch-mortgage-form"
                data-netlify="true"
                netlify-honeypot="bot-field"
                onSubmit={this.handleSubmitForm}
              >
                <input type="hidden" name="form-name" value="switch-mortgage-form" />
                <input type="hidden" name="subject" value="Mortgage lead from sprive.com"/>
                <div hidden>
                  <label>
                    Don’t fill this out if you're human: <input name="bot-field" />
                  </label>
                </div>

                <input
                  value={name}
                  onChange={this.handleChange}
                  name="name"
                  className="name"
                  type="text"
                  placeholder="Your name"
                  required
                />
                <input
                  value={email}
                  onChange={this.handleChange}
                  name="email"
                  className="email"
                  type="email"
                  placeholder="Your email address"
                  required
                />
                <input
                  value={phone}
                  onChange={this.handleChange}
                  name="phone"
                  className="phone"
                  type="tel"
                  placeholder="Phone number"
                  required
                />
                <textarea
                  value={message}
                  onChange={this.handleChange}
                  name="message"
                  className="comment"
                  type="text"
                  placeholder={textAreaMessage}
                  required
                />
                <button name="send-button" type="submit">
                  Request callback
                </button>
              </form>
            </div>
          </div>
        </section>
        <SuccessModalBlock ref={this.childSuccessModalBlockRef} />
      </>
    );
  }
}

export default SwitchMortgage;